import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BookingPromotionsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class BookingPromotionsStoreEffects {
  constructor(
    private dataService: BookingPromotionsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  loadBookingPromotions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ data }) => {
        return this.dataService.loadBookingPromotions(data).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  loadBookingPromotionDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadBookingPromotionDetailsRequest),
      switchMap(({ data }) =>
        this.dataService.loadBookingPromotionDetails(data).pipe(
          map((response) =>
            fromActions.LoadBookingPromotionDetailsSuccess({
              promotion: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.LoadBookingPromotionDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadBookingTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTargetPromotionRequest),
      switchMap(({ data }) =>
        this.dataService.loadBookingTarget(data).pipe(
          map((response) =>
            fromActions.loadTargetPromotionSuccess({
              bookingTargets: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadTargetPromotionFailure(error));
          }),
        ),
      ),
    ),
  );

  createBookingPromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createBookingPromotionRequest),
      switchMap(({ data }) =>
        this.dataService.createBookingPromotion(data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('promotion'),
              }),
              type: 'success',
            });
            this.router.navigate([
              `configuration/channel-manager/active-channels/${data.channel_id}/promotions`,
            ]);
            data.takeAction();
            return fromActions.createBookingPromotionSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createBookingPromotionFailure(error));
          }),
        ),
      ),
    ),
  );

  updateBookingPromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UpdateBookingPromotionRequest),
      switchMap(({ data }) =>
        this.dataService.updateBookingPromotion(data).pipe(
          switchMap((response) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('promotion'),
              }),
              type: 'success',
            });
            data.takeAction();
            return [
              fromActions.UpdateBookingPromotionSuccess(response.data),
              fromActions.loadRequest({
                data: {
                  ...data,
                  property_id: [data.property_id],
                },
              }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.UpdateBookingPromotionFailure(error));
          }),
        ),
      ),
    ),
  );

  cancelBookingPromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CancelBookingPromotionRequest),
      switchMap(({ data }) =>
        this.dataService.deleteBookingPromotion(data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('promotion'),
              }),
              type: 'success',
            });

            return fromActions.CancelBookingPromotionSuccess({
              promotion_id: data.promotion_id,
              property_xml_id: data.property_xml_id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.CancelBookingPromotionFailure(error));
          }),
        ),
      ),
    ),
  );
}
