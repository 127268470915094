import { createAction, props } from '@ngrx/store';

import {
  BookingPromotion,
  BookingTargetsPromotion,
  CreateBookingPromotionRequest,
  DeleteBookingPromotionRequest,
  EditBookingPromotionRequest,
  GetPromotionsByBookingXmlResponse,
  LoadBookingPromotionRequest,
} from '../../models';
import { LoadBookingPromotionsRequest } from '../../models/requests/load-booking-promotions.request';

export const loadRequest = createAction(
  '[Booking Promotions] Load Request',
  props<{
    data: LoadBookingPromotionsRequest;
  }>(),
);

export const loadSuccess = createAction(
  '[Booking Promotions] Load Success',
  props<{ items: GetPromotionsByBookingXmlResponse[] }>(),
);

export const loadFailure = createAction(
  '[Booking Promotions] Load Failure',
  props<{ error: any }>(),
);

export const loadTargetPromotionRequest = createAction(
  '[Booking Promotions] Load Target Promotion Request',
  props<{
    data: LoadBookingPromotionsRequest;
  }>(),
);

export const loadTargetPromotionSuccess = createAction(
  '[Booking Promotions] Load Target Promotion Success',
  props<{ bookingTargets: BookingTargetsPromotion }>(),
);

export const loadTargetPromotionFailure = createAction(
  '[Booking Promotions] Load Target Promotion Failure',
  props<{ error: any }>(),
);

export const createBookingPromotionRequest = createAction(
  '[Booking Promotions] Create Booking Promotion Request',
  props<{ data: CreateBookingPromotionRequest }>(),
);

export const createBookingPromotionSuccess = createAction(
  '[Booking Promotions] Create Booking Promotion Success',
);

export const createBookingPromotionFailure = createAction(
  '[Booking Promotions] Create Booking Promotion Failure',
  props<{ error: any }>(),
);

export const UpdateBookingPromotionRequest = createAction(
  '[Booking Promotions] Update Booking Promotion Request',
  props<{ data: EditBookingPromotionRequest }>(),
);

export const UpdateBookingPromotionSuccess = createAction(
  '[Booking Promotions] Update Booking Promotion Success',
  props<any>(),
);

export const UpdateBookingPromotionFailure = createAction(
  '[Booking Promotions] Update Booking Promotion Failure',
  props<{ error: any }>(),
);

export const CancelBookingPromotionRequest = createAction(
  '[Booking Promotions] Cancel Booking Promotion Request',
  props<{ data: DeleteBookingPromotionRequest }>(),
);

export const CancelBookingPromotionSuccess = createAction(
  '[Booking Promotions] Cancel Booking Promotion Success',
  props<{ promotion_id: string; property_xml_id: string }>(),
);

export const CancelBookingPromotionFailure = createAction(
  '[Booking Promotions] Cancel Booking Promotion Failure',
  props<{ error: any }>(),
);

export const LoadBookingPromotionDetailsRequest = createAction(
  '[Booking Promotions] Load Booking Promotion Details Request',
  props<{ data: LoadBookingPromotionRequest }>(),
);

export const LoadBookingPromotionDetailsSuccess = createAction(
  '[Booking Promotions] Load Booking Promotion Details Success',
  props<{ promotion: BookingPromotion }>(),
);

export const LoadBookingPromotionDetailsFailure = createAction(
  '[Booking Promotions] Load Booking Promotion Details Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Booking Promotions] Reset State');
