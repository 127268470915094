import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getBookingTargets = (state: State) => state.bookingTargets;

export const getBookingPromotion = (state: State) => state.promotion;

export const selectState = createFeatureSelector<State>('BookingPromotions');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectAllIds = featureAdapter.getSelectors(selectState).selectIds;

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectBookingTargets = createSelector(
  selectState,
  getBookingTargets,
);

export const selectBookingPromotion = createSelector(
  selectState,
  getBookingPromotion,
);
