import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.CancelBookingPromotionRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.CancelBookingPromotionSuccess,
    (state, { promotion_id, property_xml_id }) => {
      const promotions = state.entities[property_xml_id].promotions.filter(
        ({ id }) => id !== promotion_id,
      );
      return fromState.featureAdapter.updateOne(
        { id: property_xml_id, changes: { promotions: promotions } },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    },
  ),
  on(fromActions.CancelBookingPromotionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadTargetPromotionRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadTargetPromotionSuccess, (state, { bookingTargets }) => {
    return {
      ...state,
      bookingTargets,
    };
  }),
  on(fromActions.loadTargetPromotionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.LoadBookingPromotionDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.LoadBookingPromotionDetailsSuccess, (state, { promotion }) => {
    return {
      ...state,
      promotion,
    };
  }),
  on(fromActions.LoadBookingPromotionDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createBookingPromotionRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createBookingPromotionSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.createBookingPromotionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.UpdateBookingPromotionRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.UpdateBookingPromotionSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.UpdateBookingPromotionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function BookingPromotionsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
